@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
//@import url("weather-icons.min.css");

html,
body {
    background-color: #282c34;
    //color: #eee;
    font-family: "Roboto", sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
}

.loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .MuiCircularProgress-root {
        height: 64px !important;
        width: 64px !important;
    }

    &::after {
        content: 'loading';
        color: rgba(255, 255, 255, 0.3);
        font-weight: 100;
        letter-spacing: 1px;
    }
}

// .vignette {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     height: 100vh;
//     box-shadow: 0 0 500px 10px rgba(0, 0, 0, 0.9) inset;
//     pointer-events: none;

//     opacity: 1;
//     animation: fade-in 1.5s ease-in-out;

//     z-index: 10;

//     @keyframes fade-in {
//         0% {
//             opacity: 0;
//         }
//     }
// }

// .toolbar {
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 100;
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;
//     background: rgba(0, 0, 0, 0.5);
//     width: calc(100% - 64px);
//     padding: 8px 32px;
//     ///color: #999;

//     a {
//         color: #999;
//         margin: auto 8px;
//     }

//     .tb-left {
//         display: flex;
//         flex-grow: 1;
//     }

//     .tb-right {
//         display: flex;
//         flex-grow: 0;
//         font-size: 12px;
//         align-items: center;

//         span {
//             padding-right: 8px;
//         }

//         .change-color {
//             cursor: pointer;
//             color: #999;
//         }
//     }

// }

// .curr-colors {
//     position: fixed;
//     right: 32px;
//     text-align: right;
//     top: 40px;
//     font-size: 12px;

//     div {
//         &:before {
//             content: '[ ';
//         }

//         &:after {
//             content: ' ]';
//         }
//     }
// }


.page-nav {
    position: fixed;
    right: 20px;
    top: 20vh;
    display: flex;
    flex-direction: column;
    z-index: 9;

    &.dark {
        a {
            border-color: #333;
        }
    }

    &.light {
        a {
            border-color: #ddd;
        }
    }

    a {
        height: 8px;
        width: 8px;
        display: block;
        border: 1px solid;
        border-radius: 8px;
        margin: 16px auto;
        transition: all 1s;

        &.dot-active.light {
            background-color: #eee;
        }

        &.dot-active.dark {
            background-color: #333;
        }
    }
}

.mainPage {
    transition: all 2s;
    opacity: 1;
    animation: fade-in 1s ease-in-out;
    //color: red;
    //background-image: url("../Assets/patterns/pattern-1.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    //background-color: #282c34;


    &.bg-pattern-1-light {
        background-image: url("../Assets/patterns/pattern-1-light.svg");
    }

    &.bg-pattern-1-dark {
        background-image: url("../Assets/patterns/pattern-1-dark.svg");
    }

    &.bg-pattern-2-light {
        background-image: url("../Assets/patterns/pattern-2-light.svg");
    }

    &.bg-pattern-2-dark {
        background-image: url("../Assets/patterns/pattern-2-dark.svg");
    }

    &.bg-pattern-3-light {
        background-image: url("../Assets/patterns/pattern-3-light.svg");
    }

    &.bg-pattern-3-dark {
        background-image: url("../Assets/patterns/pattern-3-dark.svg");
    }

    &.bg-pattern-4-light {
        background-image: url("../Assets/patterns/pattern-4-light.svg");
    }

    &.bg-pattern-4-dark {
        background-image: url("../Assets/patterns/pattern-4-dark.svg");
    }

    //transition: background-image 2s;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
    }

    .mainPage-bg {
        position: fixed;
        height: 150vh;
        width: 150vw;
        z-index: -1;
        top: -25vh;
        left: -25vw;
    }
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.page-1 {
    align-items: flex-end;

    .name-holder {
        margin: 0 64px 0 0;

        h1 {
            margin: 0;
            font-size: 12vw;
            font-weight: 900;
            text-shadow: 0px 0px 5px rgba(100, 100, 100, 0.8);
            letter-spacing: -0.3vw;
            line-height: 10.5vw;
            cursor: pointer;
        }

        h3 {
            text-align: left;
            font-weight: 300;
            margin: 0 0 8px 4px;
            font-size: 2vw;
            align-self: flex-start;
            //color: rgba(255, 255, 255, 0.5);
            opacity: 1;
        }

        h4 {
            font-weight: 300;
            font-size: 2vw;
            opacity: 1;
            margin: 4px 0 0 0;
            display: flex;
            justify-content: end;

            .role-container {

                //width: fit-content;
                //padding: 0 16px;
                .role-bracket {
                    font-weight: 400;

                    &.rb-left {
                        padding-right: 8px;
                    }

                    &.rb-right {
                        padding-left: 8px;
                    }
                }
            }
        }
    }
}

.name-holder {
    $speed: 1.9s;
    $speed2: 1.5s;

    &.landscape {
        .greeting {
            position: relative;
            top: 0px;
            right: 0;
            opacity: .7;
            animation: slide-in-top $speed2 ease-in-out;
        }

        .name {
            position: relative;
            right: 0px;
            opacity: 1;
            animation: slide-in-right $speed ease-in-out;
            display: flex;
        }

        .role {
            position: relative;
            bottom: 0px;
            right: 0;
            opacity: .7;
            animation: slide-in-bottom $speed2 ease-in-out;
        }

        // @keyframes slide-in-top {
        //     0% {
        //         top: -50vh;
        //         opacity: 0;
        //     }
        // }

        // @keyframes slide-in-right {
        //     0% {
        //         opacity: 0;
        //     }
        // }

        // @keyframes slide-in-bottom {
        //     0% {
        //         bottom: -50vh;
        //         opacity: 0;
        //     }
        // }
    }

    &.portrait {
        margin: -132px 0 0 0;
        width: 100vw;

        .greeting {
            margin: 0 0 16px 0;
            padding: 0;
            text-align: center;
            font-size: 4vw;
            position: relative;
            top: 0px;
            right: 0;
            opacity: .7;
            animation: slide-in-top $speed2 ease-in-out;
        }

        .name {
            padding: 0 0;
            width: 100vw;
            text-align: center;
            position: relative;
            justify-content: center;
            opacity: 1;
            display: flex;
            font-size: 20vw;
            flex-direction: column;
            animation: slide-in-right $speed ease-in-out;

            span {
                padding: 16px 0;
            }

            .name-space {
                display: none;
            }
        }

        .role {
            width: 100vw;
            text-align: center;
            justify-content: center;
            font-size: 4vw;
            position: relative;
            bottom: 0px;
            right: 0;
            opacity: .7;
            animation: slide-in-bottom $speed2 ease-in-out;

            .role-container {
                .role-bracket {
                    color: inherit;
                }
            }
        }

        @keyframes slide-in-top {
            0% {
                top: -50vh;
                opacity: 0;
            }
        }

        @keyframes slide-in-right {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 0;
            }
        }

        @keyframes slide-in-bottom {
            0% {
                bottom: -50vh;
                opacity: 0;
            }
        }
    }
}

.page-2-holder {

    .page-2-content {

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 64px 16px;
        margin: 16px;
        border-radius: 16px;
        // background-color: rgba(255, 255, 255, 0.05);
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .tagline {
        margin: auto 8vw auto;
        font-size: 2.7vw;
        font-weight: 200;

        strong {
            font-weight: 700;
        }
    }

    .tagline-icons {
        display: flex;
        justify-content: space-between;
        margin: 32px 8vw auto;

        svg {
            //margin: 16px 16px;
            opacity: 0.7;
            //padding: 16px 16px;
            height: 24px;
            width: 24px;
            margin: auto 16px;
        }
    }

    &.portrait {
        .tagline {
            margin: auto 2vw auto;
            font-size: 24px;
        }

        .tagline-icons {
            margin: 32px 2vw auto;
        }
    }

}

li {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 16px 0;
}

.time-date-info {
    color: inherit;
}

.time-date-info .title {
    font-weight: 300;
    //color: rgba(255, 255, 255, 0.5);
    opacity: 0.5;
    font-size: 16px;
}

.time-date-info .title-time {
    font-weight: 300;
    //color: rgba(255, 255, 255, 0.5);
    opacity: 0.5;
    font-size: 14px;
    padding-top: 4px;
}

.today {
    font-size: 24px;
}

.time {
    font-size: 18px;
    //color: rgba(255, 255, 255, 0.7);
    opacity: 0.7;
}

.section {
    margin: 16px 0;
    //color: rgba(255, 255, 255, 0.7);
    //opacity: 0.7;

    .title {
        font-weight: 300;
        //color: rgba(255, 255, 255, 0.5);
        opacity: 0.5;
        font-size: 16px;
    }

    .info {
        opacity: 0.7;
    }
}

.section-count {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.section-count .section {
    margin: auto 16px;
}

.weather-card-bg {
    border-radius: 8px;
    padding: 8px;
    transition: background-color 2s;
    backdrop-filter: blur(1.5px);
}

.current-weather {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin: 16px;
    padding: 32px;
    @extend .weather-card-bg;

    .cw-weather-top {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .cw-weather-icon {
        font-size: 32px;
        width: fit-content;
    }


    .cw-description {
        text-transform: capitalize;
        font-size: 32px;
        font-weight: 400;
    }

    .cw-temp {
        margin: 0 12px;
        font-size: 32px;
        font-weight: 400;

        .celsius {
            font-size: 18px;
            vertical-align: super;
            font-weight: 500;
        }
    }


    $iconSize: 32px;



    .cw-temp-items {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 4px;
    }

    .wi {
        font-size: $iconSize;
        margin: 4px 8px;
    }

    .cw-wind-item {
        display: flex;
        flex-direction: column;
    }

    .cw-sun-item {
        display: flex;
        flex-direction: column;
    }

}

// .recommendation {
//     font-size: 24px;
//     //color: rgba(255, 255, 255, 0.9);
//     opacity: 0.9;
//     margin: 24px auto;
//     width: 50vw;
// }

.forecast {
    display: flex;
    justify-content: stretch;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: 992px) {
        justify-content: center;
    }
}

.forecastItem {
    margin: 8px 8px;
    width: 100px;
    @extend .weather-card-bg;
    @media screen and (max-width: 992px) {
        width: 23%;
    }
    
    &:first-child {
        margin-left: 16px;
        @media screen and (max-width: 992px) {
            margin-left: 8px;    
        }
    }
    
    &:last-child {
        margin-right: 16px;
        @media screen and (max-width: 992px) {
            margin-left: 8px;    
        }
    }


    .week-day {
        font-size: 14px;
        opacity: 0.55;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 8px;
        //font-variant: small-caps;
    }

    .forecast-icon .wi {
        // forecast icon
        font-size: 32px;
        opacity: 0.7;
        margin: 8px 0 16px;
    }

    .temp {
        font-size: 12px;
        opacity: 0.7;
    }

    .temp-min-max {
        font-size: 10px;
        opacity: 0.5;
    }


    .day-description {
        font-size: 12px;
        opacity: 0.5;
        text-transform: capitalize;
    }

    // .forecast-icon {

    //     margin-top: 16px;

    //     .wi {
    //         font-size: 32px;
    //         margin-bottom: 0;
    //         margin-right: 16px;
    //         margin-left: 16px;
    //     }
    // }

}

.card {
    &.page-3 {
        &.portrait {

            height: calc(100vh - 64px);
            margin-top: 64px;

            .bottom-border {
                opacity: .25;
                height: 1px;
                width: 100%;
                display: block;
                margin: 8px 0;
            }
        }
    }

}

.display-none {
    display: none;
}