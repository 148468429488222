.search-select {
    width: 200px;

    .dropdown-content{
        position: absolute;
        width: 200px;
        //margin-left: -16px;
        padding: 0;
        margin: 0;
    }
    .dropdown-menu {
        max-height: 200px;
        width: 100% !important;
        overflow: scroll;
        width: 128px;
        text-align: left;
        margin-top: 1px;

        .dropdown-item{
            padding: 8px 8px;
            margin: 4px;
            border-radius: 8px;
            color: white;
            font-size: 14px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: rgba(255,255,255,.1);
            }
        }
    }
}

.search-input {
    margin: 0;
    width: 168px;
    /* position: relative; */
    border: 0;
    border-bottom: 2px solid rgba(56, 171, 126, 0.6);
    border-radius: 0;
    padding-bottom: 12px;
    margin-top: 8px;
}