// $container-width: 178px;
// $container-height: 210px;
// $container-width: 25vw;
// $container-height: 25vh;
$container-width: 224px;
$container-height: 256px;

.wallet {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    .show-hide {

        position: absolute;
        top: 35.5px;
        left: 32px;
        background: rgba(0, 0, 0, 0.25);
        border-radius: 0 0 4px 4px;
        padding: 4px 12px;
        font-size: 10px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.25);
        z-index: 9999;

        @media only screen and (max-width: 400px) {
            position: fixed;
            top: calc(100vh - 86px);
            width: fit-content;
            /* bottom: 100px; */
            height: fit-content;
            right: 0;
            left: calc(100vw - 96px);
            background: rgba(0, 0, 0, 1);
            border-radius: 4px 4px 0 0;
            padding: 4px 12px;
            font-size: 10px;
            cursor: pointer;
            color: rgba(255, 255, 255, 1);
        }

    }

    h2 {
        font-size: 64px;
        font-weight: 900;
        position: absolute;
        margin-top: -500px;
        opacity: 0;
        transition: all 0.5s ease-in-out;
        background-color: rgba(0, 0, 0, 0.1);
        //color: transparent;
        text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        z-index: 9999;

        margin-top: 0;
        top: 10px;
        font-size: 14px;
        color: white;;

        &.show {
            opacity: .7;
        }
    }

    .coins {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        .coin-item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;


            .coin {
                width: $container-width;
                height: $container-height;
                // margin: 8px 8px 0;
                margin: 1vh 1vw 0;
                background-image: linear-gradient(180deg, #2af59899 0%, #009efd99 100%);
                border-radius: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-size: 16px;
                z-index: 2;

                @media only screen and (max-width: 590px) {
                    margin: 64px 32px 0;
                }

                .coin-inner {
                    color: white;
                    //transform: rotate(-45deg); // Rotates the element 90 degrees
                    background: rgba(0, 0, 0, 0.75);
                    box-shadow: inset 0px 0px 8px 2px black;
                    // width: $container-width - 1vw;
                    // height: $container-height - 1vh;
                    width: $container-width - 8px;
                    height: $container-height - 8px;
                    border-radius: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    //padding: 12px;
                    div {
                        margin: 8px 0;
                    }

                    .ico {
                        height: 48px;
                        width: 48px;
                        margin: 0 0 4px;
                        cursor: pointer;
                    }

                    .title {
                        font-size: 18px;
                        font-weight: 500;
                    }

                    .title_s {
                        font-size: 11px;
                        font-weight: 300;
                        margin: 2px 0;

                        .count {
                            opacity: 0;
                            transition: all 0.2s ease-in-out;
                            display: none;

                            &.show {
                                display: inline-block;
                                opacity: 1;
                            }
                        }
                    }

                    .price {
                        font-size: 16px;
                        font-weight: 500;
                    }

                    .change {
                        font-size: 11px;
                        font-weight: 500;
                        display: inline-block;
                        padding: 1px 6px;
                        border-radius: 4px;
                        margin-top: 8px;

                        &.down {
                            background: #970000;
                        }

                        &.up {
                            background: #008100;
                        }
                    }
                }
            }

            .owned {
                font-size: 10px;
                font-weight: 300;
                background-color: rgba(0, 0, 0, 0.25);
                padding: 8px 8px;
                border-radius: 0 0 4px 4px;
                box-shadow: inset 0px 4px 4px 0px black;
                position: absolute;
                bottom: 0;
                z-index: 1;
                opacity: 0;
                transition: all 0.2s ease-in-out;
                color: white;

                &.show {
                    bottom: -28px;
                    opacity: 1;
                }

            }
        }
    }
}

.pop {
    transition: all 0.2s ease-in-out;
    top: 0;
    position: relative;
    cursor: pointer;

    &.showing-totals {
        top: 32px;
    }
}

.pop-form {
    display: none;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: -9999;

    &.show {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .amount-input {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .delete-amount {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        margin: 0;
        padding: 0;

        svg {
            height: 12px;
            width: 12px;
        }
    }

    .pop-form-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 16px;

        div {
            width: 100%;
        }
    }
}