.App {
  //background-color: #282c34;
  // background-color: #152a2f;
  // background-image: linear-gradient(62deg, #152a2f 0%, #384560 100%);
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  color: white;
}

.vignette {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  box-shadow: 0 0 500px 10px rgba(0, 0, 0, 0.9) inset;
  pointer-events: none;

  opacity: 1;
  animation: fade-in 1.5s ease-in-out;

  z-index: 10;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
  }

  @media screen and (max-width: 992px) {
    box-shadow: 0 0 50px 1px rgba(0, 0, 0, 0.9) inset;
  }
}

.abstract-base {
  circle {
    // cx: calc(100vw / 2);
    // cy: calc(100vh / 2);
  }
}

.abstract-bg {
  position: fixed;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;

  //opacity: 0;
  &.dark {
    fill: black;
    stroke: black;
  }

  &.light {
    fill: white;
    stroke: white;
  }
}

.abstract-bg svg {
  width: 100vw;
  height: 100vh;
}

.abstract-bg-fade-in {
  //transition: all 1s;
  opacity: 1;

  circle {
    transition: all 1s;
  }
}

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  width: calc(100% - 64px);
  padding: 8px 32px;
  ///color: #999;

  a {
    color: #999;
    margin: auto 8px;
  }

  .tb-left {
    display: flex;
    flex-grow: 1;
  }

  .tb-right {
    display: flex;
    flex-grow: 0;
    font-size: 12px;
    align-items: center;

    span {
      padding-right: 8px;
    }

    .change-color {
      cursor: pointer;
      color: #999;
    }
  }

}

.curr-colors {
  position: fixed;
  right: 32px;
  text-align: right;
  top: 40px;
  font-size: 12px;

  div {
    &:before {
      content: '[ ';
    }

    &:after {
      content: ' ]';
    }
  }
}

.toolbar {
  display: flex;
  align-items: center;

  .tb-left {
    div {
      display: flex;
      align-items: center;
    }

    svg {
      height: 16px;
    }

  }

  .tb-right {

    div {
      display: flex;
      align-items: center;
    }

    svg {
      height: 12px;
    }
  }
}

.page-content {
  width: 100vw;
  position: relative;
  //height: 100vh;
}

.internal-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  margin: auto;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 900;
  background: rgba(0, 0, 0, 0.5);

  button {
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    margin: 16px;
    cursor: pointer;

    //margin: 16px 0;
    font-size: 22px;
    font-weight: 700;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: white;
      height: 32px;
      width: 32px;
      margin-left: 8px;
    }
  }

  .menu-icon {
    padding: 16px;
    cursor: pointer;
    color: white;

    svg {
      height: 32px;
      width: 32px;
    }
  }

  .internal-menu-pages {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    top: 0;
    padding: 16px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(16px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &.active {
      display: flex;
    }

    .close-icon svg {
      height: 40px;
      width: 40px;
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 16px;
      margin-right: 16px;
    }

    a {
      margin: 16px 0;
      font-size: 24px;
      font-weight: 700;
      color: white;
    }



  }

}

.house {
  //margin-top: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 64px);
  padding-top: 64px;
}

.page-trash {
  .days {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0;
      padding: 0;
      font-size: 18px;
      flex: 1;

      span {
        font-weight: 300;
        font-size: 12px;
        display: block;
      }
    }

    .day-svg {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .trash-svg {
      height: 70%;
      width: auto;
    }



    $day-width: 300px;
    $day-height: 300px;

    .day {
      width: $day-width;
      height: $day-height;
      max-width: 500px;
      //background-image: linear-gradient(180deg, #2af59899 0%, #009efd99 100%);
      background-image: linear-gradient(180deg, #2af598cc 0%, #009efdcc 100%);
      margin: 8px;
      padding: 8px;
      border-radius: 8px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);

      .day-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        box-shadow: inset 0px 0px 4px 1px black;
        color: #4EA9DD;
        color: #ddd;
        font-weight: 900;
        border-radius: 6px;
        padding: 16px;
        box-sizing: border-box;
      }
    }

    .coming {
      display: flex;
      flex-direction: row;

      h2 {
        font-size: 14px;
      }

      .day {
        width: calc($day-width / 2 - 16px);
        height: calc($day-height / 2);
        padding: 4px;
      }

      .trash-svg {
        height: 60%;
        //max-width: 300px;
        //width: auto;
        //margin: 16px;
      }
    }

  }
}

.page-laundry {

  .washing-machine-svg {
    width: 70vw;
    height: auto;
    margin: 16px;
  }

  .laundry-items {
    display: none;
    flex-direction: row;

    $item-width: 160px;
    $item-height: 52px;

    .item {
      width: $item-width;
      height: $item-height;
      //background-image: linear-gradient(180deg, #2af59899 0%, #009efd99 100%);
      background-image: linear-gradient(180deg, #2af598cc 0%, #009efdcc 100%);
      margin: 8px;
      padding: 2px;
      border-radius: 8px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);

      .item-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        box-shadow: inset 0px 0px 4px 1px black;
        color: #4EA9DD;
        color: #ddd;
        font-weight: 900;
        border-radius: 6px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .washing-machine-svg {
      display: none;
    }

    .laundry-items {
      display: flex;
    }
  }
}

.page-crypto {
  padding-bottom: 128px;
  height: auto;
  min-height: 100vh;
  box-sizing: border-box;

  .input {
    padding: 8px 16px;
    margin: 8px;
    border-radius: 8px;
    border: none;
    //background-color: #111;
    background-color: #081823;
    color: white;
    font-size: 14px;
    //border: 1px solid #555;
    border: 2px solid #38AB7E;

    &:focus {
      outline: none;
    }


  }

  .btn {
    padding: 6px 14px;
    margin: 8px;
    border-radius: 4px;
    border: none;
    background-color: #777;
    color: white;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #555;
    }


    &.btn-primary {
      background-color: #38AB7E;

      &:hover {
        background-color: #2E8A61;
      }

    }

    &.btn-secondary {
      background-color: #081823;
      border: 2px solid #38AB7E;
      border-radius: 8px;

      &:hover {
        background-color: #000023;
      }

    }

    &.btn-danger {
      background-color: #970000;

      &:hover {
        background-color: #7A0000;
      }
    }
  }
}

.page-flashcards {
  //padding-bottom: 128px;
  height: auto;
  min-height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  //margin-top: 32px;

  .card {
    transition: background-color 2s;
    backdrop-filter: blur(1.5px);

    position: relative;
    height: 400px;
    width: 500px;
    background-color: white;
    border-radius: 16px;
    margin: 0 0 32px 0;

    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    margin-top: -10vh;

    .card-language {
      opacity: 0.8;
      font-size: 32px;
      margin:0;
      padding: 0;
    }

    .card-language-pronounce {
      opacity: 0.6;
      font-size: 16px;
    }

    .card-answer {
      position: absolute;
      bottom: 0;
      opacity: 0.6;
      font-size: 16px;
      // top:0;
    }
  }

  button {
    border: none;
    outline: none;
    background: green;
    font-size: 16px;
    border-radius: 8px;
    padding: 8px 16px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    margin: 8px;

    &.btn-show {
      background: rgba(0, 0, 0, 0.2);
    }

    &.btn-next {
      background: rgba(0, 0, 0, 0.5);
    }


  }

  .btn-swap {
    margin-top: 16px;
    background: none;
    font-size: 12px;
  }

  .m-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    padding: 8px;
    z-index: 1000;

    svg {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  @media screen and (max-width: 992px) {
    .card {
      height: 27vh;
      width: 77vw;
    }
  }
}

a {
  color: white;
  text-decoration: none;
}