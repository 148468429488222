.page-workouts {

    .menu-item {
        margin: 4px 8px;
        padding: 4px 8px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.75);
        box-shadow: inset 0px 0px 4px 1px black;
        color: #ddd;
        font-weight: 900;
        font-size: .75rem;
        cursor: pointer;
        transition: all 0.3s;
        border: 2px solid transparent;

        &:hover {
            background: rgba(0, 0, 0, 0.9);
        }

        &.active {
            border: 2px solid rgba(42, 245, 152, 0.5);
            background: rgba(255, 255, 255, 0.5);
            color: rgba(0, 0, 0, 0.75);
        }

        @media screen and (max-width: 500px) {
            margin: 4px 2px;
        }
    }

    .workout-select {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 8px;

        .ws-item {
           @extend .menu-item;
        }
    }

    .workout-nav {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        .wn-item {
            @extend .menu-item;
        }
    }

    .workout {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        background-image: linear-gradient(180deg, rgba(42, 245, 152, 0.8) 0%, rgba(0, 158, 253, 0.8) 100%);
        margin: 8px;
        padding: 4px 4px 12px 4px;
        border-radius: 8px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);

        width: 90%;
        max-width: 512px;


        .workout-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.75);
            box-shadow: inset 0px 0px 4px 1px black;
            color: #4EA9DD;
            color: #ddd;
            //font-weight: 900;
            font-size: .9rem;
            border-radius: 6px;
            padding: 0 32px 8px;

            h4 {
                font-size: 1.75rem;
                text-align: left;
                margin: 32px 0 8px 0;
            }

            p {
                margin: 16px 0;
                text-align: left;
            }

            ul,
            ol {
                margin: 0;
                padding: 0 0 0 24px;
            }

            li {
                display: list-item;
                list-style: auto;
                margin: 0 0 6px 0;
                font-weight: bold;
                text-align: left;
            }

            span {
                font-weight: normal;
                display: block;
                color: #999;
            }

            @media screen and (max-width: 500px) {
                //font-size: .7rem;

                h4 {
                    font-size: 1.5rem;
                }
            }

        }

    }
}